import { Component, OnInit, AfterViewInit, Output, EventEmitter, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { NouiFormatter } from 'ng2-nouislider';
import { RepoService, NadmParams } from '../_services/repo.service';
import { QueryParams } from '../_models/postParams';
import { SuperFormControlComponent } from './super-form-control.component';
import { CompositionControlComponent } from './composition-control.component';
import { ViewParams } from '../_models/viewParams';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements AfterViewInit {
  Repo = RepoService;
  @Output() selectionDone = new EventEmitter<{
    zoningIds: string[],
    formTypesIds: string[],
    queryParams: QueryParams
  }>();

  @ViewChild("zoning") zoning: SuperFormControlComponent;
  @ViewChild("formTypes") formTypes: SuperFormControlComponent;
  readonly star = ViewParams.star;

  
  constructor(public repo: RepoService) {
  }
  ngAfterViewInit() {
  }

  zoningAllSelected(): boolean {
    if (this.zoning.loading) return false;
    return this.zoning.treeControl.dataNodes.every(
      item => this.zoning.checklistSelection.isSelected(item)
    );
  }
  zoningPartiallySelected(): boolean {
    if (this.zoning.loading) return false;
    return this.zoning.treeControl.dataNodes.some(
      item => this.zoning.checklistSelection.isSelected(item)
    ) && !this.zoningAllSelected();
  }
  zoningAllToggle() {
    if (this.zoningAllSelected())
      this.zoning.checklistSelection.clear();
    else
      this.zoning.checklistSelection.select(...this.zoning.treeControl.dataNodes);
  }
  getRayonIds(): string[] {
    let res = new Array<string>();
    res = this.zoning.getIds();
    return res;
  }

  formTypesAllSelected(): boolean {
    if (this.formTypes.loading) return false;
    return this.formTypes.treeControl.dataNodes.every(
      item => this.formTypes.checklistSelection.isSelected(item)
    );
  }
  formTypesPartiallySelected(): boolean {
    if (this.formTypes.loading) return false;
    return this.formTypes.treeControl.dataNodes.some(
      item => this.formTypes.checklistSelection.isSelected(item)
    ) && !this.formTypesAllSelected();
  }
  formTypesAllToggle() {
    if (this.formTypesAllSelected())
      this.formTypes.checklistSelection.clear();
    else
      this.formTypes.checklistSelection.select(...this.formTypes.treeControl.dataNodes);
  }
  getFormTypesIds(): string[] {
    let res = new Array<string>();
    res = this.formTypes.getIds();
    return res;
  }

  getQueryParams(): QueryParams {
    let res = new QueryParams();
    return res;
  }

  done() {
    let zoningIds = this.getRayonIds();
    let formTypesIds = this.getFormTypesIds();
    let queryParams = this.getQueryParams();
    this.selectionDone.emit({
      zoningIds: zoningIds,
      formTypesIds: formTypesIds,
      queryParams: queryParams
    });
  }
}
