import { Pipe, PipeTransform } from '@angular/core';
import { Epoch, RepoService } from '../_services/repo.service';

@Pipe({
  name: 'insideEpoch'
})
export class InsideEpochPipe  implements PipeTransform {

  transform(value: any, min: number, max: number): any {
    const epoch: Epoch = null;// RepoService.TectEpoch.find(epoch => (min >= epoch.minage && max <= epoch.maxage));
    return epoch ? epoch.title : "нет вхождения";
  }

}
