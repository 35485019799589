import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'magmperiod'
})
export class MagmperiodPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if (!value) return "";
    if(value.includes("верхний")) return value.replace("верхний","поздний");
    if(value.includes("нижний")) return value.replace("нижний","ранний");
    if(value.includes("Верхний")) return value.replace("Верхний","Поздний");
    if(value.includes("Нижний")) return value.replace("Нижний","Ранний");
    if(value.includes("Верхняя")) return value.replace("Верхняя","Поздняя");
    if(value.includes("Нижняя")) return value.replace("Нижняя","Ранняя");
    return value;
  }

}
