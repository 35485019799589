import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { FilterComponent } from './filter/filter.component';
import { ListComponent } from './list/list.component';
import { TableComponent } from './table/table.component';
import { ChartsComponent } from './charts/charts.component';
import { DialogComponent } from './_entry/dialog/dialog.component';
import { MessageBarComponent } from './_entry/message-bar/message-bar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NouisliderModule } from 'ng2-nouislider';
import { AppService } from './_services/app.service';
import { APP_BASE_HREF } from '@angular/common';
import { RepoService } from './_services/repo.service';
import { CubesService } from './_services/cubes.service';
import { StratService } from './_services/strat.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatIconModule, MatButtonModule, MatInputModule, MatSelectModule, MatRadioModule, MatSnackBarModule, MatProgressBarModule, MatCheckboxModule, MatTreeModule, MatBadgeModule, MatListModule, MatDialogModule, MatTabsModule } from '@angular/material';
import { StratAgesComponent } from './table/strat-ages.component';
import { SuperFormControlComponent } from './filter/super-form-control.component';
import { CompositionControlComponent } from './filter/composition-control.component';
import { BarComponent } from './charts/bar.component';
import { SingleChartComponent } from './charts/single-chart.component';
import { CutTextPipe } from './_pipes/cut-text.pipe';
import { HighlightPipe } from './_pipes/highlight.pipe';
import { InsideEpochPipe } from './_pipes/inside-epoch.pipe';
import { ChartHeightDirective } from './_directives/chart-get-height.directive';
import { MagmperiodPipe } from './_pipes/magmperiod.pipe';
import { FormationStyleComponent } from './list/formation-style.component';
import { DiaComponent } from './dia/dia.component';

export function app_Init(appService: AppService) {
  return () => appService.getConfigs().then(res => {
    return;// appService.getLex();
  });
}
@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    FilterComponent, SuperFormControlComponent, CompositionControlComponent,
    TableComponent, StratAgesComponent,
    ChartsComponent, SingleChartComponent, BarComponent,
    ListComponent,
    DialogComponent, MessageBarComponent,
    CutTextPipe, HighlightPipe, InsideEpochPipe, ChartHeightDirective, MagmperiodPipe, FormationStyleComponent, DiaComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NouisliderModule,
    FormsModule, ReactiveFormsModule,
    HttpClientModule,
    MatIconModule, MatButtonModule, MatInputModule, MatSelectModule, MatRadioModule,
    MatSnackBarModule, MatSelectModule, MatProgressBarModule, MatButtonModule, MatCheckboxModule, 
    MatTreeModule, MatIconModule, MatBadgeModule,MatRadioModule,MatInputModule,MatListModule,
    MatDialogModule, MatTabsModule
  ],
  providers: [
    AppService,
    { provide: APP_INITIALIZER, useFactory: app_Init, deps: [AppService], multi: true },
    RepoService,
    CubesService,
    StratService,
  ],
  entryComponents: [
    DialogComponent,
    MessageBarComponent
  ], bootstrap: [AppComponent]
})
export class AppModule { }
