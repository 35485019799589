import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Column } from '../_models/column';
import { Cube, Hatching } from '../_models/cube';
import { ViewParams } from '../_models/viewParams';
import { svgHelper } from '../_models/svgHelper';
import { Config } from '../_services/app.service';


const svgns = 'http://www.w3.org/2000/svg';
const xlink = 'http://www.w3.org/1999/xlink';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Input() columns: Column[];
  @Input() selcubes: Cube[];
  @Input() strelnikoffmode: boolean;
  @Output() cubeSelected = new EventEmitter<Cube>();

  readonly coeffY = ViewParams.coeffY;
  readonly rowsnumber = ViewParams.rowsnumber;
  readonly rowsarr = Array(this.rowsnumber);
  readonly tooltipWidth = ViewParams.tooltipWidth;
  readonly tooltipHeight = ViewParams.tooltipHeight;
  readonly linespacing = ViewParams.linespacing;
  readonly star = ViewParams.star;
  readonly cgrey = ViewParams.darkgrey;
  readonly cred = ViewParams.darkred;
  readonly hdHeight = ViewParams.hdHeight;
  readonly rowarrEpoch = [32, 51, 62, 78, 89, 100, 111];

  cubesWidth: number;
  cubesCharcount: number = 13;
  zoommode: number = 1;
  fullWidth: number = 0;

  currentFnt: number = 12;
  currentWht: number = 500;

  Hatching = Hatching;

  constructor() {
    this.cubesWidth = Math.round(ViewParams.cubesWidth * this.zoommode);
    this.cubesCharcount = Math.round(this.cubesWidth / 6) - 4;
    this.columns = new Array<Column>();
  }

  ngOnInit() {
  }



  dValue(i): string {
    if (this.columns.length == 0) return '';
    return "M 0," + (i + 1) * this.coeffY + " L " + this.columns[this.columns.length - 1].finalX * this.cubesWidth + "," + (i + 1) * this.coeffY;
  }

  isSelected(cube) {
    if (this.selcubes.filter(c => c == cube).length == 0) return 'deselectedcube';
    else return 'selectedcube';
  }
  tongleselect(i, j) {
    let column: Column = this.columns[j];
    this.cubeSelected.emit(column.cubes[i]);
  }

  showPopup(i, j, x, y) {

    if (document.getElementById("popup") != undefined) return;

    let padding = this.linespacing;
    let xp = x + padding;
    let column: Column = this.columns[j];
    let cube = column.cubes[i];
    let id = 'c' + j + 'g' + i;
    let cube_g = document.getElementById(id);


    let container = document.createElementNS(svgns, "g");
    container.setAttributeNS(null, "id", "popup");

    let text = document.createElementNS(svgns, "text");
    text.setAttributeNS(null, "x", x);
    text.setAttributeNS(null, "y", y);
    text.setAttributeNS(null, "text-anchor", "start ");
    let dy = 1;
    let dx = 1;

    text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "Класс  формации:", true, 0));
    dy++;
    text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, cube.formationClass, false, padding));
    dy++;
    text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "Тип формации:", true, 0));
    dy++;
    text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, cube.formationType, false, padding));
    dy++;
    if (cube.facii) {
      text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "Фация:", true, 0));
      dy++;
      text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, cube.facii, false, padding));
      dy++;
    }
    text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "Комплексы:", true, 0));
    dy++;
    cube.complexesNames.forEach(item => {
      text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, item, false, padding));
      dy++;
    });

    text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "Количество массивов:", true, 0));
    dy++;
    text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, cube.baseaIds.length, false, padding));
    dy++;
    text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "Геодин. обстан.:", true, 0));
    dy++;
    cube.geodynPositions.forEach(item => {
      text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, item, false, padding));
      dy++;
    });

    container.appendChild(text);

    // samples
    if (cube.samples.length > 0) {
      text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "Геохронололичекие анализы проб:", true, 0));
      dy++;
    }
    cube.samples.forEach(item => {
      let linktxt = item.datingObject + ' ' + item.mapUnit;
      if (linktxt == ' ') linktxt = item.sampleID + '';
      container.appendChild(svgHelper.createxlink(xp, y, dy * this.linespacing, linktxt, padding, Config.GeochronServiceUrl + item.sampleID));
      let tempdx = container.lastChild.textContent.length + 5;
      dx = tempdx < dx ? dx : tempdx;
      dy++;
    });
    // global samples
    if (cube.globSamples.length > 0) {
      text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "Опубликованные данные:", true, 0));
      dy++;
    }
    cube.globSamples.forEach(item => {
      let linktxt = item.journalName + ' ' + item.pubYear;
      if (linktxt == ' ') linktxt = item.sampleID + '';
      container.appendChild(svgHelper.createxlink(xp, y, dy * this.linespacing, linktxt, padding, Config.GeochronAtlasServiceUrl + item.sampleID));
      let tempdx = container.lastChild.textContent.length + 5;
      dx = tempdx < dx ? dx : tempdx;
      dy++;
    });

    for (let i in text.childNodes)
      if (text.childNodes[i].textContent != undefined) {
        let tempdx = text.children[i].textContent.length;
        dx = tempdx < dx ? dx : tempdx;
      }
    // rect
    let rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
    rect.setAttributeNS(null, "x", x);
    rect.setAttributeNS(null, "y", y);
    rect.setAttributeNS(null, "height", String(dy * this.linespacing));
    rect.setAttributeNS(null, "width", String(Math.floor(7.3 * dx) + 3 * padding));
    rect.setAttributeNS(null, "fill", 'rgb(255,255,255)');
    rect.setAttributeNS(null, "rx", "2");
    rect.setAttributeNS(null, "stroke", "lightgrey");
    rect.setAttributeNS(null, "class", "cubepopup");

    container.insertBefore(rect, container.firstChild);
    cube_g.parentElement.appendChild(container);
    let listener = () => {
      if (cube_g.parentElement.contains(container)) {
        cube_g.parentElement.removeChild(container);
        cube_g.removeEventListener('mouseleave', listener, false);
      }
    };
    cube_g.parentElement.addEventListener('mouseleave', listener, false);
  }

  invert(j: number) {
    return this.columns.length - j - 1;
  }

  textrows(cube: Cube): string[] {
    let res: string[] = new Array();
    let rowscount = (cube.ageMaxY - cube.ageMinY) * 4 - 1;
    if (rowscount == 3) {
      res.push(cube.formationType.slice(0, this.cubesCharcount));
      res.push(cube.formationType.slice(this.cubesCharcount, 2 * this.cubesCharcount));
    }
    else {
      let formationType = [];
      let regexp = new RegExp('.{1,' + this.cubesCharcount + '}', 'g');
      if (cube.formationType == "" || !cube.formationType) {
        formationType = "Тип формации не указан".match(regexp) as string[];
      }
      else {
        formationType = cube.formationType.match(regexp) as string[];
      }
      for (let i = 0; i < formationType.length && i < rowscount / 2; i++) {
        res.push(formationType[i])
      }
    }

    return res.splice(0, rowscount);
  }

}
