import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Rayon } from 'src/app/_models/rayon';

@Component({
  selector: 'app-message-bar',
  templateUrl: './message-bar.component.html',
  styleUrls: ['./message-bar.component.scss']
})
export class MessageBarComponent {

  @Input()
  rayons: Rayon[];

  @Output()
  onDismissClick= new EventEmitter<any>();

  dismissClicked() {
     this.onDismissClick.emit(null);
  }

}
