import { Cube } from './cube';

export class Column {
    cubes: Cube[];
    cubes1vulc: Cube[];
    cubes2gipo: Cube[];
    cubes3pluto: Cube[];
    title: string;
    maxN1vulc: number;
    maxN2gipo: number;
    maxN3pluto: number;
    maxN: number;
    startX: number;
    finalX: number;

    constructor(props: { cubes: Cube[], title: string }) {
        Object.assign(this, props)
    }
    Sort() {
        this.cubes.sort((a, b) => {
            if (a.ageMaxY > b.ageMaxY) return 1;
            if (a.ageMaxY < b.ageMaxY) return -1;
            return 0;
        })
        this.cubes1vulc = this.cubes.filter(c => c.formationClass == "Вулканический");
        this.maxN1vulc = this.SortPart(this.cubes1vulc, 0);

        this.cubes2gipo = this.cubes.filter(c => c.formationClass == "Гипабиссальный");
        this.maxN2gipo = this.SortPart(this.cubes2gipo, this.maxN1vulc);

        this.cubes3pluto = this.cubes.filter(c => c.formationClass == "Плутонический");
        this.maxN3pluto = this.SortPart(this.cubes3pluto, this.maxN2gipo);

        this.maxN = this.maxN3pluto;


    }
    private SortPart(cubes: Cube[], maxN) {
        let n = maxN;
        let temp: Cube[] = new Array();
        if (cubes.length == 0) n++;
        while (cubes.find(cube => cube.line == -1) != undefined) {
            cubes.forEach(cube => {
                if (cube.line == -1) {
                    if (temp.length == 0) {
                        cube.line = n;
                        temp.push(cube);
                    }
                    else {
                        if (!(temp[temp.length - 1].ageMaxY > cube.ageMinY)) {
                            cube.line = n;
                            temp.push(cube);
                        }
                    }
                }
            });
            temp = new Array();
            n++;
            if (n > 100) break;
        }
        cubes.sort((a, b) => {
            if (a.line > b.line) return -1;
            if (a.line < b.line) return 1;
            return 0;
        })
        return n;
    }
}
export class ColumnHeader {
    width: number;
    title: string;
}
