import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cutText'
})
export class CutTextPipe implements PipeTransform {

  transform(value: string, args?: number): any {
    if (!args || value.length < args) return value;
    return value.slice(0, args - 3) + '...';
  }

}
