
declare function unescape(s: string): string;
declare function escape(s: string): string;
export class svgHelper {
    static createxlink(x, y, dy, content, padding, xlink): SVGAElement {
        let link = document.createElementNS("http://www.w3.org/2000/svg", "a");
        let text = document.createElementNS("http://www.w3.org/2000/svg", "text");
        link.setAttributeNS("http://www.w3.org/1999/xlink", "href", xlink);
        link.setAttributeNS("http://www.w3.org/1999/xlink", "show", 'new');
        text.setAttributeNS(null, "x", x);
        text.setAttributeNS(null, "y", y);
        text.setAttributeNS(null, "dx", padding);
        text.setAttributeNS(null, "dy", dy);
        text.setAttributeNS(null, "stroke-width", "0");
        text.setAttributeNS(null, "font-size", "14px");
        text.setAttributeNS(null, "font-weight", "600");
        text.setAttributeNS(null, "fill", 'rgba(2,136,209,0.9)');

        if (content.length > 80) content = String(content).slice(0, 77) + '...';
        text.textContent = content;
        text.style.cursor = "pointer";
        link.appendChild(text);
        return link;
    }

    static createtspan(x, y, dy, content, isbold, padding): SVGElement {
        let tspan = document.createElementNS("http://www.w3.org/2000/svg", "tspan");
        tspan.setAttributeNS(null, "x", x);
        tspan.setAttributeNS(null, "y", y);
        tspan.setAttributeNS(null, "dx", padding);
        tspan.setAttributeNS(null, "dy", dy);
        tspan.setAttributeNS(null, "stroke-width", "0");
        tspan.setAttributeNS(null, "font-size", "14");
        tspan.setAttributeNS(null, "font-weight", "500");
        tspan.setAttributeNS(null, "fill", 'rgba(0,0,0,0.8)');

        if (content.length > 80) content = String(content).slice(0, 77) + '...';
        tspan.textContent = content;
        if (isbold) {
            tspan.setAttributeNS(null, "font-weight", "600");
            tspan.setAttributeNS(null, "fill", '#007060');
        }
        return tspan;
    }
    static replaceTextByPath(text: SVGElement, glyphs): SVGElement[] {
        let res: SVGElement[] = new Array()
        let mapindex = text.innerHTML.trim();
        let x = Number(text.getAttribute('x')) + Number(text.getAttribute('dx'));
        let y = Number(text.getAttribute('y')) + Number(text.getAttribute('dy'));
        if (text.getAttribute('text-anchor') == "middle") x = x - 10;
        else y = y - 5;
        for (let i = 0; i < mapindex.length; i++) {
            let d = svgHelper.getD(mapindex[i], glyphs);
            let path = document.createElementNS("http://www.w3.org/2000/svg", "path");
            path.setAttributeNS(null, "stroke", "rgba(0, 0, 0, 0.8)");
            // path.setAttributeNS(null, "stroke-width", "4");
            path.setAttributeNS(null, "d", d);
            path.setAttributeNS(null, "transform", "translate(" + String(11 * i + x) + ", " + String(10 + y) + ") scale(0.01, -0.01)");
            res.push(path);
        }
        return res.reverse();
    }
    private static getD(char: string, glyphs): string {
        let d = '';
        for (let i = 0; i < glyphs.length; i++) {
            if (glyphs[i].getAttribute('unicode') == char) {
                d = glyphs[i].getAttribute('d');
            }
        }
        return d;
    }
    static tds2svg(tds: HTMLCollectionOf<HTMLTableDataCellElement>): string {
        let svg0_string = ""; let dx = 0;
        for (let i in tds)
            if (tds[i].style != undefined) {
                let width = Number(tds[i].style.getPropertyValue('min-width').replace('px', ''));
                svg0_string += svgHelper.rect(dx, 0, width, 50, 'white', 'rgb(50,50,50)');
                let title = tds[i].innerText;
                if (title.length > width / 10) {
                    let space = title.indexOf(" ", title.length / 2 - 5);
                    svg0_string += svgHelper.text(dx + width / 2, 16, 16, 'black', title.substr(0, space));
                    svg0_string += svgHelper.text(dx + width / 2, 34, 16, 'black', title.substr(space + 1, title.length - space - 1));
                }
                else
                    svg0_string += svgHelper.text(dx + width / 2, 25, 16, 'black', title);
                dx += width;
            }
        return svg0_string;
    }
    static svg2Img(svgstr: string, title: string, h: number, w: number) {

        var imgsrc = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgstr)));
        var canvas = document.createElement('canvas');
        let context = canvas.getContext("2d");
        canvas.width = w;
        canvas.height = h;

        var image = new Image(w * 2, h * 2);
        image.src = imgsrc;
        image.onload = function () {
            context.drawImage(image, 0, 0);
            var canvasdata = canvas.toDataURL('image/jpeg', 2.0);
            svgHelper.dataURIToBlob(canvasdata, title);
        };

    }
    static dataURIToBlob(dataURI, title: string) {
        var binStr = atob(dataURI.split(',')[1]),
            len = binStr.length,
            arr = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i);
        }
        svgHelper.callback(new Blob([arr]), title);
    }
    static callback(blob, title: string) {
        var a = document.createElement('a');
        // a.download = title + ".jpg";
        a.download = title + ".jpg";
        a.href = URL.createObjectURL(blob);
        document.body.appendChild(a);
        a.click();
        a.remove();
    }
    static rect(x: number, y: number, w: number, h: number, fill: string, stroke: string): string {
        let res = '<rect x="' + x + '" y="' + y + '" width="' + w + '" height="' + h + '" fill="' + fill + '" stroke="' + stroke + '"></rect>';
        return res;
    }
    static text(x: number, y: number, fsize: number, fill: string, text: string): string {
        if (fill == 'black')
            fill = 'rgba(0, 0, 0, 0.8)';
        let res = '<text x="' + x + '" y="' + y + '" alignment-baseline="central" letter-spacing="1px" stroke-width="0"' +
            ' text-anchor="middle" font-size="' + fsize + '" font-weight="300" fill="' + fill + '">' + text + '</text>';
        return res;
    }
    static WrapTxt(n:number, s:string): string[]{
    let consist = s.split(' ');
    let consistres = new Array();  // provide text wrap
    let temp = consist[0]
    for (let i = 1; i < consist.length; i++) {
      if ((temp + consist[i]).length < 30) {
        temp += ' ' + consist[i];
      }
      else {
        consistres.push(temp);
        temp = consist[i];
      }
    }
    consistres.push(temp);
    return consistres;
    }
}