
export class ViewParams {
    static coeffX: number = 40;// stratages table row's width
    static coeffY: number = 70;// stratages table row's height
    static hdHeight: number = 60; // column header's height
    static rowsnumber: number = 39;
    static cubesWidth: number = 100;
    static tooltipWidth: number = 300;
    static tooltipHeight: number = 500;
    static linespacing: number = 16;
    static darkgrey = 'rgb(50,50,50)';
    static darkred = 'rgb(51, 122, 183)';// 'rgb(150,50,50)';
    static star = "M 12 3 L 16.7 16 L 6 8 L 18 8 L 7.6 16 Z";
    // static star = "M 12 2.4 L 17.7 18 L 3.4 8.0 L 20.5 8.0 L 6.6 18 Z";
    static rome: string[]=['I','II','III','IV','V','VI','VII','VII','IX','X','XI','XII','XIII','XIV','XV','XVI','XVII','XVII','XIX','XX','XXI','XXII'];
}