import { Component, OnInit, ViewChild, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { RayonCharts } from '../_models/diagramclasses';
import { ViewParams } from '../_models/viewParams';
import { BarComponent } from './bar.component';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements AfterViewInit {
  charts: RayonCharts[] = [];
  maxvalue: number;
  rome: string[] = ViewParams.rome;
  glyphs: any;
  @ViewChildren(BarComponent) barcharts: QueryList<BarComponent>;

  constructor() {
  }

  ngAfterViewInit() {
    this.updateCharts();
  }
  updateCharts() {
    this.barcharts.forEach(ch => ch.clearData());
    this.barcharts.forEach(ch => ch.updateData());
  }
  export2xls() {
    let table = document.getElementsByClassName("column1")[0].cloneNode(true) as HTMLTableElement;
    let tds = table.getElementsByTagName("td");
    for (let i in tds)
      if (tds[i].innerHTML != undefined) {
        let td = tds[i];
        td.innerHTML = td.innerHTML.replace('.', ',');
      }
    var blob = new Blob([table.innerHTML], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
    });
    var xlsUrl = URL.createObjectURL(blob);
    var downloadLink = document.createElement("a");
    downloadLink.href = xlsUrl;
    downloadLink.download = "chart.xls";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
}
