import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { RayonCharts } from '../_models/diagramclasses';
import { RepoService, Epoch } from '../_services/repo.service';
import { ViewParams } from '../_models/viewParams';
import { svgHelper } from '../_models/svgHelper';

declare var require: any
//var Plotly = require('plotly.js-dist');

@Component({
  selector: 'app-bar',
  template: '<div [id]="n" #chart></div>'
})
export class BarComponent {

  @Input() rayonCharts: RayonCharts;
  @Input() glyphs;
  @Input() n;

  @ViewChild('chart') el: ElementRef;

  epochs: Epoch[] = [];//RepoService.TectEpoch;
  //rome: string[] = ViewParams.rome;

  constructor() { }

  public clearData() {
    (this.el.nativeElement as HTMLDivElement).innerHTML = "";
  }
  public updateData() {
    console.log("updateData ", this.n);
    let romeN = 0;
    let forms = this.rayonCharts.indtypes.map(x => x[2]);

    var ticks = new Array(this.epochs.length);
    var tickvals = new Array(this.epochs.length + 1);
    var ticknames = new Array(this.epochs.length + 1);
    tickvals[0] = 0.01;
    ticknames[this.epochs.length] = '3500 Ma';
    for (let i = 0; i < this.epochs.length; i++) {
      ticks[i] = i + 1;
      tickvals[i + 1] = i + 1.5;
      ticknames[i] = this.epochs[i].minage + ' Ma';
    }

    let squares = new Array(forms.length);
    let tooltips = new Array(forms.length);

    for (let i = 0; i < squares.length; i++) {
      squares[i] = new Array<number>(forms.length);
      tooltips[i] = new Array<string>(forms.length);
    }
    // for (let chart of this.rayonCharts.charts) {
    //   for (let i = 0; i < squares.length; i++) {
    //     let square = squares[i];
    //     let tooltip = tooltips[i];
    //     let form = forms[i];
    //     const column = chart.columns.find(c => c.indtype == form);
    //     if (column) {
    //       const coeff = column.cubes[0].form_type == 'A' ? 1 : -1;
    //       square[romeN] = coeff * column.value;
    //       tooltip[romeN] = column.cubes[0].rus_txt;
    //     }
    //     else {
    //       square[romeN] = 0;
    //       tooltip[romeN] = '';
    //     }
    //   }
    //   romeN++;
    // }
    console.log(this.epochs.map(e => e.title));

    let traces = [];
    for (let i = 0; i < squares.length; i++) {
      const f_key = this.rayonCharts.indtypes[i][0];
      const f_txt = svgHelper.WrapTxt(40, this.rayonCharts.indtypes[i][3]).join('<br>      ');
      const f_color = this.rayonCharts.indtypes[i][1];
      const trace = {
        x: ticks,
        y: squares[i],
        marker: {
          color: f_color
        },
        name: f_key,
        type: 'bar',
        text: this.epochs.map(e => e.title),
        hovertemplate:
`<b style="font-family: -Roboto, 'Helvetica Neue', sans-serif; font-size:14; font-weight:400 !important;">
<b style="color: #007060;"'>Эпоха:</b><br>
    %{text}<br>
<b style="color: #007060;"'>Состав:</b><br>
    ${f_txt}<br>
<b style="color: #007060;"'>Общая площадь:</b><br>
    %{y} км <sup>2</sup></b>`
      };
      traces.push(trace);
    }
    const layout = {
      hovermode: 'closest',
      hoverlabel: {
        bgcolor: "#FFF",
        bordercolor: "#CCC",
        font: {
          family: 'GigGGK16_3', color: "#222222", size: 16
        }
      },
      title: 'Суммарная площадь комплексов в различные эпохи тектогенеза',
      showlegend: true,
      xaxis: {
        title: '',
        tickvals: tickvals,
        ticktext: ticknames,
        zeroline: true
      },
      yaxis:
      {
        title: '',
        zeroline: false
      },
      height: 600,
      width: 1400,
      barmode: 'relative',
      legend: {
        font: {
          family: 'GigGGK16_3',
          size: 12,
          color: '#000'
        }
      }
    };
    const config = {
      displayModeBar: true,
      displaylogo: false,
      toImageButtonOptions: {
        format: 'jpeg', // one of png, svg, jpeg, webp
        filename: 'gragh',
        scale: 1 // Multiply title/legend/axis/canvas sizes by this factor
      }
    };


    //Plotly.newPlot(this.el.nativeElement, traces, layout, config, { responsive: true });;



  }


}
