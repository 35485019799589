import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private urlDefaultConfig = "./assets/config.default.json";
  private urlLocalConfig = "./assets/config.json";

  constructor(private http: HttpClient) {
  }
  getConfigs(): Promise<Config> {
    return this.http.get(this.urlDefaultConfig)
      .toPromise()
      .then((configJson) => {
        Config.UpdateFromJson(configJson);
        return 'ok';
      })
      .then(() => {
        return this.http.get(this.urlLocalConfig).toPromise();
      })
      .then((configJson) => {
        Config.UpdateFromJson(configJson);
        return 'ok';
      })
      .catch(()=>{
        //console.log("no local config");
        return "ok";
      });
  }
}

export class Config {
  static MapUrl: string;
  static WebAPIServiceUrl: string;
  static StratAgesUrl: string;
  static StratAgesColorUrl: string;
  static Zoning: string;
  static FormTypes: string;
  static CompositionColor: string;
  static StratagelexiconUrl: string;
  static CubesUrl: string;
  static StorageUrl: string;
  static GeochronServiceUrl: string;
  static GeochronAtlasServiceUrl: string;
  static MagfMapGuid: string;

  static UpdateFromJson(res: any) {
    if (res['MapUrl'])
      Config.MapUrl = res['MapUrl'];
    if (res['WebAPIServiceUrl'])
      Config.WebAPIServiceUrl = res['WebAPIServiceUrl'];
    if (res['StratAgesUrl'])
      Config.StratAgesUrl = res['StratAgesUrl'];
    if (res['StratAgesColorUrl'])
      Config.StratAgesColorUrl = res['StratAgesColorUrl'];
    if (res['Zoning'])
      Config.Zoning = res['Zoning'];
    if (res['FormTypes'])
      Config.FormTypes = res['FormTypes'];
    if (res['CompositionColor'])
      Config.CompositionColor = res['CompositionColor'];
    if (res['StratagelexiconUrl'])
      Config.StratagelexiconUrl = res['StratagelexiconUrl'];
    if (res['CubesUrl'])
      Config.CubesUrl = res['CubesUrl'];
    if (res['StorageUrl'])
      Config.StorageUrl = res['StorageUrl'];
    if (res['GeochronServiceUrl'])
      Config.GeochronServiceUrl = res['GeochronServiceUrl'];
    if (res['GeochronAtlasServiceUrl'])
      Config.GeochronAtlasServiceUrl = res['GeochronAtlasServiceUrl'];
    if (res['MagfMapGuid'])
      Config.MagfMapGuid = res['MagfMapGuid'];
  }
}