import { Injectable } from '@angular/core';
import { Composition } from '../_models/composition';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JsonNode } from '../filter/super-form-control.component';
import { Config } from './app.service';

const babinRayon: Lexitem[] = [
  { text: "ЗАПАДНО-СИБИРСКАЯ ПЛИТА", code: "Западно-Сибирская" },
  { text: "Чехол Сибирской платформы", code: "чехол Сибирской платформы" },
  { text: "Краевые выступы фундамента Сибирской платформы", code: "краевые выступы Сибирской платформы" },
  { text: "Алданская тектоническая провинция", code: "Алдано-Становой щит, Алданская провинция" },
  { text: "Становая тектоническая провинция", code: "Алдано-Становой щит, Становая провинция" },
  { text: "Обь-Зайсанская складчатая область герцинид", code: "Обь-Зайсанская" },
  { text: "Складчатые системы раннекаледонской складчатости", code: "Алтае-Саянская раннекаледонская" },
  { text: "Складчатые системы позднекаледонской складчатости", code: "Алтае-Саянская позднекаледонская" },
  { text: "Енисейская складчатая область байкалид", code: "Енисейская" },
  { text: "Саяно-Байкальская складчатая область байкалид", code: "Саяно-Байкальская" },
  { text: "Западно-Становая складчатая область", code: "Западно-Становая" },
  { text: "Гоби-Хинганская область докембрийской консолидации", code: "Гоби-Хинганская" },
  { text: "Монголо-Охотская складчатая область", code: "Монголо-Охотская" },
  { text: "Верхояно-Колымская складчатая область", code: "Верхояно-Колымская" },
  { text: "Охотская складчатая область", code: "Охотская" },
  { text: "ОХОТОМОРСКИЙ ОКРАИННО-МОРСКОЙ БАССЕЙН", code: "Охотоморский" },
  { text: "ЯПОНОМОРСКИЙ ОКРАИННО-МОРСКОЙ БАССЕЙН", code: "Япономорский" },
  { text: "Восточно-Азиатский вулкано-плутонический пояс", code: "Восточно-Азиатский пояс" },
  { text: "Ханкайский мегаблок", code: "кратонные блоки" },
]

@Injectable({
  providedIn: 'root'
})
export class RepoService {

  public static Zoning: Lexitem[];
  zoning: Promise<JsonNode[]> = this.getZoning();
  public static FormTypes: Lexitem[];
  formTypes: Promise<JsonNode[]> = this.getFormTypes();

  constructor(private http: HttpClient) {
  }
  getFont(): Promise<any> {
    const headers = new HttpHeaders();
    headers.set('Accept', 'image/svg+xml');
    return this.http.get('./assets/fonts/GigGGK16_3.svg', { headers, responseType: 'text' }).toPromise();
  }

  getStorageDataId(): Promise<string> {
      return this.http.get(Config.StorageUrl + '/api/Tree/GetNodes')
        .toPromise()
        .then(
          res => {
            let id = (res as any[]).find(x => x['key'] == '3')['id'];
            return id;
          },
          err => {
            console.log(err);
            return null;
          });      
  }
  getStorageData(id): Promise<any[]> {
      return this.http.get(Config.StorageUrl + '/api/Tree/GetNodes?id=' + id)
        .toPromise()
        .then(
          res => {
            return res as any[];
          },
          err => {
            console.log(err);
            return null;
          });
  }
  getData(url: string): Promise<JsonNode[]> {
    return this.http.get(url)
      .toPromise()
      .then(
        res => {
          return res as JsonNode[];
        },
        err => {
          console.log(err);
          return null;
        });
  }
  getZoning(): Promise<JsonNode[]> {
    return this.http.get(Config.WebAPIServiceUrl + Config.Zoning)
      .toPromise()
      .then(
        (data: []) => {
          let res = new Array<JsonNode>();
          RepoService.Zoning = new Array<Lexitem>();
          for (let i in data) {
            const d = data[i];
            res.push({ Name: d, ID: i, ParentID: '' });
            RepoService.Zoning.push(new Lexitem(i, d))
          }
          return res;
        },
        err => {
          console.log(err);
          return null;
        });
  }
  getFormTypes(): Promise<JsonNode[]> {
    return this.http.get(Config.WebAPIServiceUrl + Config.FormTypes)
      .toPromise()
      .then(
        (data: []) => {
          let res = new Array<JsonNode>();
          RepoService.FormTypes = new Array<Lexitem>();
          for (let i in data) {
            const d = data[i];
            res.push({ Name: d, ID: i, ParentID: '' });
            RepoService.FormTypes.push(new Lexitem(i, d))
          }
          return res;
        },
        err => {
          console.log(err);
          return null;
        });
  }
  getNodes(data: any): Array<JsonNode> {
    let temp = new Array<JsonNode>();
    for (let s in data) {
      if (data[s] instanceof Object) {
        let item = babinRayon.find(i => i.text == s);
        temp.push({
          Name: s,
          ID: item ? item.code : s,
          ParentID: '',
          Children: this.getNodes(data[s])
        });
      }
    }
    return temp;
  }

  handleError(message: string, postparams: NadmParams[]) {
    console.log('--- message ---');
    console.log(message);
    console.log('--- postparams ---');
    console.log(postparams);
  }

}



export interface NadmParams {
  property: string;// "EARTH_MATERIAL_ID" | "GENETIC_PROCESS_1" | "GENETIC_ENVIRONMENT_1" | "COMPOSITION_1";
  value: string;
}

export interface Epoch {
  title: string;
  // tooltip: string;
  minage: number;
  maxage: number;
  color: string;
}

export class Lexitem {
  code: string;
  text: string;
  constructor(code: string, text: string) {
    this.code = code;
    this.text = text;
  }
}
