import { Directive, Input, ElementRef } from '@angular/core';
import { RayonCharts } from '../_models/diagramclasses';

@Directive({
  selector: '[appChartHeight]'
})
export class ChartHeightDirective {
  @Input() val: number;
  @Input() hgt: number;
  @Input() max: number;
  @Input() rayonCharts: RayonCharts;
  @Input() k: number;
  @Input() w: number;
  private static N = 0;
  constructor(private element: ElementRef) {
  }
  ngOnInit() {
    let h = Math.floor(this.val * this.hgt / this.max);
    h = h < 10 ? 10 : h;
    console.log(ChartHeightDirective.N + '  -  appChartHeight  -  ' + h);
    console.log(this.val , this.hgt , this.max);
    ChartHeightDirective.N++;
    let y = 300 - h;
    (this.element.nativeElement as SVGRectElement).setAttributeNS(null, "y", y + '');
    (this.element.nativeElement as SVGRectElement).setAttributeNS(null, "height", h + '');
    if (this.k == -1) return 0;
    let res = 0;
    for (var i = 0; i < this.k; i++) {
      res += this.rayonCharts.charts[i].columns.length * this.w + 40;
    }
    return res;

  }


}
