import { Component, OnInit, ViewChild, TemplateRef, HostListener } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatDialog } from '@angular/material';
import { Column } from '../_models/column';
import { Cube, Hatching } from '../_models/cube';
import { ChartsComponent } from '../charts/charts.component';
import { Rayon } from '../_models/rayon';
import { TableComponent } from '../table/table.component';
import { RepoService } from '../_services/repo.service';
import { CubesService } from '../_services/cubes.service';
import { QueryParams } from '../_models/postParams';
import { StratService, sNode } from '../_services/strat.service';
import { RayonCharts, ChartHelper } from '../_models/diagramclasses';
import { ViewParams } from '../_models/viewParams';
import { Config } from '../_services/app.service';
import { svgHelper } from '../_models/svgHelper';

const snackconfig: MatSnackBarConfig = {
  verticalPosition: 'bottom',
  horizontalPosition: 'right'
}
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  activeTab: 'obj-select' | 'corr-table' | 'charts' | 'list' | 'dia' = 'obj-select';
  totalWidth: number = 0;
  columns: Column[] = new Array<Column>();
  selectedcubes: Cube[] = new Array();
  loading = false;
  tasks: number = 0;
  fonts;
  map: Window;// = window.opener;
  timer;
  storageData: any[];
  Config=Config;

  // @ViewChild(ChartsComponent) charts: ChartsComponent;
  @ViewChild(TableComponent) maintable: TableComponent;
  @ViewChild('snackBarTemplate') snackBarTemplate: TemplateRef<any>;
  rayonsProgress: Rayon[] = [];


  constructor(
    private repo: RepoService,
    private service: CubesService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog) { }

  ngOnInit() {
    (async () => {
      this.fonts = await this.repo.getFont();
      let parser = new DOMParser();
      let fonts = parser.parseFromString(this.fonts, "application/xml");
      let glyphs = fonts.getElementsByTagName("glyph");
      let s_id = await this.repo.getStorageDataId();
      this.storageData = await this.repo.getStorageData(s_id);
      // this.charts.glyphs = glyphs;
    })();
    this.zoommode('normal');
    if (document.referrer.includes("wega")) this.map = window.opener;
  }

  public dismissSnackbar(): void {
    this.snackBar.dismiss();
  }


  // ******************
  // *** GET CUBES ***
  // ******************
  // #region 
  getColumns(event) {
    console.log(event);
    const ids: string[] = event.zoningIds;
    const formTypesIDList: string[] = event.formTypesIds;
    let pars: QueryParams = event.queryParams;
    this.columns = new Array<Column>();

    this.rayonsProgress = [];
    this.snackBar.openFromTemplate(this.snackBarTemplate, snackconfig);
    pars.formTypes = RepoService.FormTypes.
          filter(r => formTypesIDList.includes(r.code) ).
          map( r => r.text);

    ids.forEach(id => {
      pars.zoning = RepoService.Zoning.find(r => r.code == id).text;
      const title = pars.zoning;

      this.rayonsProgress.push({ id: id, title: title, loading: true, count: undefined })
      this.addTask(true, '', -1);
      this.getColumn(pars, title, +id);
    });
  }

  getColumn(queryParams: QueryParams, title: string, code: number) {
    this.service.getColumn(queryParams).subscribe((data) => {
      let cubes: Cube[] = [];
      if (data == null) {
        this.addTask(false, code + '', 0);
        return;
      }
      for (let n in data) {
        let cube: Cube = new Cube();
        Object.assign(cube, data[n])
        cube.ageMinTxt = StratService.Titles[cube.saMin];
        cube.ageMaxTxt = StratService.Titles[cube.saMax];
        if (cube.ageMinTxt == undefined || cube.ageMaxTxt == undefined){
          console.log(cube.formationType, " кубик не добавлен, т.к. некорректный индекс ", cube.formationAge);
          continue;
        }
        let yvalues = StratService.MinMaxAgeY(cube.ageMinTxt, cube.ageMaxTxt);
        cube.ageMinY = yvalues[0];
        cube.ageMaxY = yvalues[1];
        cube.height = cube.ageMaxY-cube.ageMinY;
        cube.line = -1;
        cubes.push(cube);
      }
      let column = new Column({
        cubes: cubes,
        title: title//cubes[0].rayon
      });
      if (column.cubes.length) {
        column.Sort();
        if (this.columns.length == 0) {
          column.startX = 0;
          column.finalX = column.maxN;// + 1;
        }
        else {
          column.startX = this.columns[this.columns.length - 1].finalX;
          column.finalX = column.startX + column.maxN ;//+ 1;
        }
        this.maintable.fullWidth = column.finalX * this.maintable.cubesWidth;
        const table = document.getElementById('content') as HTMLDivElement;
        table.style.width = String(this.maintable.fullWidth + 800) + 'px';
        this.columns.push(column);
        this.addTask(false, code + '', column.cubes.length);
      }
      else
        this.addTask(false, code + '', 0);
    },
      error => {
        this.service.handleError(error, queryParams);
        this.addTask(false, code + '', -1);
      });
  }
  addTask(increment: boolean, id: string, count: number) {
    this.tasks = increment ? this.tasks + 1 : this.tasks - 1;
    if (!increment) {
      let item = this.rayonsProgress.find(item => item.id == id);
      item.loading = false;
      item.count = count;
    }
    this.loading = this.tasks != 0;
    if (this.loading == false) this.afterAllLoading();
  }
  afterAllLoading() {
    let charts: RayonCharts[] = ChartHelper.createChart(this.columns);
    let maxvalue = 0;
    for (let rch of charts) {
      rch.SetValues();
      if (maxvalue < rch.maxvalue) maxvalue = rch.maxvalue;
    }
    maxvalue = Math.ceil(maxvalue / 100) * 100;
    let linearr = new Array();
    for (let i = 0; i < 6; i++) {
      linearr.push(i * maxvalue / 5);
    }
    // this.charts.charts = charts;
    // this.charts.maxvalue = maxvalue;

    // setTimeout(() => {
    //   this.charts.updateCharts();
    // }, 500);
    setTimeout(() => {
      this.dismissSnackbar();
    }, 3000);
  }
  // #endregion 

  // ******************
  // *** EVENTS ***
  // ******************
  // #region 
  cubeSelected(cube: Cube) { // event emmited from corr-table
    let index = this.selectedcubes.indexOf(cube);
    if (index == -1) this.selectedcubes.push(cube);
    else this.selectedcubes.splice(index, 1);
  }
  scrollToCube(e) {
    const j = e.colN;
    const i = e.cubeN;
    const id = 'c' + j + 'r' + i;
    this.activeTab = 'corr-table';
    const goalElement = document.getElementById(id);
    const cube = this.columns[j].cubes[i];
    let index = this.selectedcubes.indexOf(cube);
    if (index == -1) this.selectedcubes.push(cube);
    setTimeout(() => { goalElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" }); }, 500);
  }

  periodSelected(node: sNode) { // clicked strat age
    let ymin = node.y + node.h;
    let ymax = node.y;
    let filteredcubes: Cube[] = new Array();
    let isselect = true;
    this.columns.forEach(col => {
      col.cubes.forEach(cube => {
        let cubeymin = cube.ageMinY;
        let cubeymax = cube.ageMaxY;
        let cond1 = cubeymin >= ymax && cubeymin < ymin;
        let cond2 = cubeymax > ymax && cubeymax <= ymin;
        let cond3 = cubeymax > ymax && cubeymin < ymin;
        if (cond1 || cond2 || cond3) {
          filteredcubes.push(cube);
          if (this.selectedcubes.find(c => c == cube) == undefined) isselect = false;
        }
      })
    });
    if (isselect) {
      this.selectedcubes = this.selectedcubes.filter(c => filteredcubes.find(k => k == c) == undefined);
    }
    else {
      filteredcubes.forEach(c => this.selectedcubes.push(c));
    }
  }
  // #endregion 

  // ******************
  // *** imgbuttons ***
  // ******************
  // #region
  zoommode(mode) {
    let buttonnormal = document.getElementById("modenormal") as HTMLButtonElement;
    let buttoncompact = document.getElementById("modecompact") as HTMLButtonElement;
    if (mode == 'normal') {
      this.maintable.zoommode = 1;
      buttonnormal.disabled = true;
      buttoncompact.disabled = false;
    }
    else {
      this.maintable.zoommode = 0.7;
      buttonnormal.disabled = false;
      buttoncompact.disabled = true;
    }
    let w = Math.round(ViewParams.cubesWidth * this.maintable.zoommode);
    this.maintable.cubesWidth = w;
    if (this.columns.length)
      this.maintable.fullWidth = this.columns[this.columns.length - 1].finalX * this.maintable.cubesWidth;
    this.maintable.cubesCharcount = Math.round(w / 6) - 4;
  }
  selectAll() { // clicked selectAll
    this.selectedcubes = new Array();
    this.columns.forEach(col => {
      col.cubes.forEach(cube => {
        this.selectedcubes.push(cube);
      })
    });
  }
  deselectAll() { // clicked deselectAll
    this.selectedcubes = new Array();
  }
  @HostListener('window:message', ['$event'])
  selectFromMap(event) {
    if (event.data && event.data.type == "map") {
      const ids: string[] = event.data.ids as string[];
      console.log(ids);
      // this.selectOnMap({ type: 'prs_array', id: probIds.map(p => { return p.nprob }) })
    }
  }
  selectOnMap() {
    if (!this.map || this.map.closed) {
      this.map = window.open(Config.MapUrl);
    }
    let res = new Array();
    let complexNamesList = new Array();
    let zonesList = new Array();
    let FaciiList = new Array();
    let containsNullFacii = false; // в списке есть кубы без фации. В этом случае фильтр (FACCI IN (1,2,3)) задавать нельзя - пустые не найдутся

    this.selectedcubes.forEach(cube => {
        complexNamesList.push("'" + cube.complexesNames + "'");
        if (cube.facii && cube.facii.length > 0) {
          FaciiList.push("'" + cube.facii + "'");
        }
        else 
        {
          containsNullFacii = true;
        }
        if (cube.zoning) {
          zonesList.push("'" + cube.zoning + "'");
        }
      }
    );

    let msg = {
      type: 'filterWegaMap',
      mapId: Config.MagfMapGuid,
      filter: []
    }

    if (complexNamesList.length > 0){
      msg.filter.push({field:"COMPLEX_NAME", condition: "in", value: complexNamesList.join(",") });
    }
    if (!containsNullFacii && FaciiList.length > 0){
      msg.filter.push({field:"FACII", condition: "in", value: FaciiList.join(",") });
    }
    if (zonesList.length > 0){
      msg.filter.push({field:"STRUCTFORMZONE", condition: "in", value: zonesList.join(",") });
    }

    const sendCubes = () => { setTimeout(() => { this.map.postMessage(msg, '*'); }, 1000) };
    this.map.focus();
    sendCubes();
    // this.map.addEventListener('load', sendCubes);
    // else {
    //   this.map.removeEventListener('load', sendCubes);
    // }
  }
  // #endregion   
  
  // ******************
  // *** EXPORT ***
  // ******************
  // #region 

  export2img() {
    
      // Params
      const h = ViewParams.rowsnumber * ViewParams.coeffY + ViewParams.hdHeight;
      const sw = 5 * ViewParams.coeffX; // strat width
      const fw = this.maintable.fullWidth + sw; //full width
      const title = 'magmform';
      const cropTop = 0;
      const cropBottom = 0;
      // Agestitles
      const stratheader = document.getElementById('stratheader').innerHTML;
      const strattable = document.getElementById('strattable').innerHTML;
      // Cubes table    
      const cubesheader = document.getElementById('cubesheader').innerHTML;

      let svg2 = document.getElementById('cubescomp').cloneNode(true) as HTMLElement;     
      let cubescomp = svg2.innerHTML.replace(new RegExp('class="galo"', 'g'), 'stroke="#FFFFFF" stroke-width="2"');
      let result = `<svg xmlns="http://www.w3.org/2000/svg" width="${fw}" height="${h - cropTop - cropBottom}">                    
                      <rect width="${fw}" height="${h - cropTop - cropBottom}" x="0" y="0" fill="white" stroke="rgb(50,50,50)"></rect>
                      <g transform="translate(0,${0 - cropTop})"> ${strattable} </g>
                      <g transform="translate(${sw},${ViewParams.hdHeight - cropTop})"> ${cubescomp} </g>
                      <g> ${stratheader} </g>
                      <g transform="translate(${sw},0)"> ${cubesheader} </g>
                    </svg>`;
      svgHelper.svg2Img(result, title, h - cropTop - cropBottom, fw);
   

  }

  // #endregion 
}
