import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class StratService {

  public static Titles: [string, string];
  public static Colors: [string, string];
  constructor(private http: HttpClient) { }


  async getStratTree(): Promise<sNode[]> {
    let colors = this.http.get(Config.StratAgesColorUrl).toPromise().then((data: [string, string]) => {
      return data;
    });
    let titles = this.http.get(Config.WebAPIServiceUrl + Config.StratagelexiconUrl).toPromise().then((data: [string, string]) => {
      return data;
    });
    StratService.Colors = await colors;
    StratService.Titles = await titles;
    return this.http.get(Config.StratAgesUrl)
      .toPromise()
      .then(
        (data) => {

          let node = new sNode({ id: StratService.n, y: 0, h: 0, parent: 0, level: -1, color: this.getColor(''), title: 'root', isLeaf: false });
          StratService.nodes.push(node);
          this.traverse(data, 0, 0);

          StratService.nodes.sort(function (a, b) {
            if (a.id > b.id) return 1;
            else return -1;
          })

          StratService.nodes.forEach(element => {
            if (element.isLeaf) this.setH(element);
          });

          return StratService.nodes;
        },
        err => {
          console.log(err);
          return null;
        });
  }


  static n: number = 0;
  static currentY = 0;
  static nodes: sNode[] = new Array<sNode>();


  private traverse(o, level, parent) {
    for (var k in o) {
      StratService.n++;
      let node = new sNode({ id: StratService.n, y: StratService.currentY, h: 0, parent: parent, level: level, color: this.getColor(k), title: k, isLeaf: false });
      if (o[k] != null) {
        this.traverse(o[k], level + 1, StratService.n);
      }
      else {
        node.isLeaf = true;
        StratService.currentY++;
        node.h = 1;
      }
      StratService.nodes.push(node);
    }
  }

  private setH(node: sNode) {
    StratService.nodes[node.parent].h++;
    if (StratService.nodes[node.parent].level > 0) this.setH(StratService.nodes[node.parent]);
  }

  private getColor(value: string): string {
    if (value == '') return 'rgb(250,250,250)';
    let res = StratService.Colors[value];
    if (res != undefined) return res;
    return 'rgb(250,250,250)';
  }

  static MinMaxAgeY(title1: string, title2: string): [number, number] {
    let node1 = StratService.NodeByStratAge(title1, true);
    let node2 = StratService.NodeByStratAge(title2, false);
    if (node1.y <= node2.y) return [node1.y, node2.y + node2.h];
    if (node1.y > node2.y) return [node2.y, node1.y + node1.h];
    return [-1, -1];
  }
  static NodeByStratAge(title: string, min: boolean): sNode {    
    switch (title) {
      case "Эоплейстоцен верхний":
      case "Плейстоцен нижний": {
        title = 'Эоплейстоцен';
        break;
      }
      case "Плейстоцен": {
        if (min) title = 'Неоплейстоцен, верхнее звено';
        else title = 'Эоплейстоцен';
        break;
      }
      case "Неоплейстоцен": {
        if (min) title = 'Неоплейстоцен, верхнее звено';
        else title = 'Неоплейстоцен, нижнее звено';
        break;
      }
      case "Верхний баррем":
      case "Нижний баррем": {
        title = 'Барремский';
        break;
      }
      case "Хирнантский":
      case "Катийский":
      case "Сандбийский": {
        title = 'Ордовик верхний';
        break;
      }
      case "Дарривильский":
      case "Дапингский": {
        title = 'Ордовик средний';
        break;
      }
      case "Флоский": {
        title = 'Ордовик нижний';
        break;
      }
      default: {
        break
      }
    }
    let node = StratService.nodes.find(node => node.title == title);
    if (node == undefined) node = StratService.nodes[1];
    else return node;
  }

}

export class sNode {
  id: number;
  y: number;
  h: number = 1;
  parent: number;
  level: number;
  color: string;
  title: string;
  isLeaf: boolean;
  constructor(props: { id: number, y: number, h: number, parent: number, level: number, color: string, title: string, isLeaf: boolean }) {
    Object.assign(this, props)
  }
  ToString(): string {
    let result = '';
    result += 'id - ';
    result += this.id;
    result += ', y - ';
    result += this.y;
    result += ', h - ';
    result += this.h;
    result += ', par - ';
    result += this.parent;
    result += ', lev - ';
    result += this.level;
    result += ', ttl - ';
    result += this.title;
    result += ', leaf - ';
    result += this.isLeaf;
    return result;
  }
  includes(n: number):boolean{ 
    const n1 = this.y;
    const n2 = n1 + this.h;
    if (n >= n1 && n < n2) return true;
    else return false;
  }


}
