import { Component, Input, AfterViewInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Composition } from '../_models/composition';

@Component({
  selector: 'app-composition-control',
  templateUrl: './composition-control.component.html'
})
export class CompositionControlComponent implements AfterViewInit {
  @Input() data: Promise<Composition[]>;
  datasource: Composition[];
  loading: boolean = true;
  others: boolean = false;
  // valid: boolean = false;

  constructor(private _elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef) {
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    (async () => {
      this.datasource = await this.data;
      this.loading = false;
      this.datasource.forEach(item => item.checked = true);
    })();
    // }, 1000);
  }

  getIds(): string[] {
    return this.datasource.filter(item => item.checked).map(item => { return item.key });
  }

  getAllFormations(): boolean {
    return !this.datasource.some(item => !item.checked);
  }
  getOtherFormations(): boolean {
    return this.others;
  }

  // private N = 0;
  // notValid(): boolean { // Validation
  //   console.log(this.N); this.N++;
  //   if (this.others) return false;
  //   return this.datasource.some(item => item.checked);
  // }

  private descendants(key): Composition[] {
    switch (key) {
      case "7": {
        return this.datasource.filter(item => item.genesis == '7');
      }
      case "8": {
        return this.datasource.filter(item => item.genesis == '8');
      }
      case "a": {
        return this.datasource.filter(item => item.type == 'a');
      }
      case "b": {
        return this.datasource.filter(item => item.type == 'b');
      }
      default: return null;
    }
  }
  descendantsAllSelected(key): boolean {
    let descendants: Composition[] = this.descendants(key);
    let result = descendants.every(item => item.checked);
    return result;
  }

  descendantsPartiallySelected(key): boolean {
    let descendants: Composition[] = this.descendants(key);
    return descendants.some(item => item.checked) && !descendants.every(item => item.checked);
  }
  selectByCriterion(key): void {
    let descendants: Composition[] = this.descendants(key);
    let value: boolean = !this.descendantsAllSelected(key);
    descendants.forEach(item => item.checked = value);
    this.changeDetectorRef.markForCheck();
  }
}
