import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { svgHelper } from 'src/app/_models/svgHelper';
import { ChartColumn, RayonCharts } from 'src/app/_models/diagramclasses';
import { ViewParams } from 'src/app/_models/viewParams';

@Component({
  selector: 'app-single-chart',
  templateUrl: './single-chart.component.html',
  styleUrls: ['./charts.component.scss']
})
export class SingleChartComponent implements OnInit {
  @Input() rayonCharts: RayonCharts;
  @Input() glyphs;
  linearr: number[] = new Array(6);
  @ViewChild("chart", { read: ElementRef }) chart: ElementRef;
  // coeff
  w: number = 25;   // ChartColumnWidth
  h: number = 240; // Chart Height
  tW: number = 0;   // table Width
  tH: number = 0; // table Height
  px: number = 70;   // Chart padding from line 
  py: number = 60;   // Chart padding from indexes, Total height = 60 + 240 + 20 px 
  darkgrey;
  indcols = new Array(1);

  line = `M 5 60 L 10 60 L 5 60 
L 5 108 L 10 108 L 5 108
L 5 156 L 10 156 L 5 156
L 5 204 L 10 204 L 5 204
L 5 252 L 10 252 L 5 252
L 5 300 L 10 300 L 5 300 Z`;
  linespacing: number;
  rome: string[]=ViewParams.rome;
  svgns = 'http://www.w3.org/2000/svg';
  constructor() {
    this.linespacing = ViewParams.linespacing;
    this.darkgrey = ViewParams.darkgrey;
  }

  ngOnInit() {
    this.indcols = new Array(Math.ceil(this.rayonCharts.indtypes.length / 14));
  }

  getConsist(ind: string) {// slice mapindex to get consistance code
    for (let i = 0; i < ind.length; i++)
      if (ind[i] == ind[i].toUpperCase())
        if (i == 0) return '-';
        else return ind.slice(0, i);
    return ind;
  }
  getheight(a, b) {
    let res = Math.floor(a * this.h / this.rayonCharts.maxvalue);
    if (res < 10) return 10;
    return res;
  }
  getDX(rayonCharts: RayonCharts, k: number) {
    if (k == -1) return 0;
    let res = 0;
    for (var i = 0; i < k; i++) {
      res += rayonCharts.charts[i].columns.length * this.w + 40;
    }
    return res;
  }
  getLinevalue(max, i) {
    return Math.ceil(max / 100) * 100 * (i / 5);
  }
  export2img() {
    let svg = this.chart.nativeElement.cloneNode(true) as SVGElement;
    let textelems = svg.getElementsByTagName('text');
    for (let i = textelems.length - 1; i >= 0; i--) {
      let text = textelems[i];
      if (text.getAttribute('font-family') == "GigGGK16_3" && text.innerHTML.length > 0) {
        let paths = svgHelper.replaceTextByPath(text, this.glyphs);
        for (let path of paths)
          text.parentElement.insertBefore(path, text);
        text.remove();
      }
    }
    let tbl = this.tds2svg();
    let width = Number(svg.getAttributeNS(null, 'width'));
    width = width > this.tW ? width : this.tW;

    let svg0Data = svgHelper.rect(0, 0, this.tW, 50, 'white', 'grey') + svgHelper.text(this.tW / 2, 25, 18, 'black', this.rayonCharts.title);
    let svg1Data = '<g transform="translate(0,50)" width="' + this.tW + '" height="' + this.tH + '">' + tbl + '</g>';
    let svg2Data = new XMLSerializer().serializeToString(svg)
      .replace('<svg xmlns="http://www.w3.org/2000/svg"',
        '<g transform="translate(0,' + String(this.tH + 50 + 10) + ')"')
      .replace('</svg>', '</g>');
    let svgData = '<svg xmlns="http://www.w3.org/2000/svg" width="' + width + '" height="' + String(this.tH + 320 + 50 + 50) + '">';
    svgData += svgHelper.rect(0, 0, width, this.tH + 320 + 50 + 50, 'white', 'white');
    svgData += svg0Data + svg1Data + svg2Data;
    svgData += '</svg>';
    svgHelper.svg2Img(svgData, 'chart', this.tH + 320 + 50 + 50, width);
  }

  showPopup(col: ChartColumn, x, y, event: MouseEvent) {

    // let padding = this.linespacing;
    // let xp = x + padding;
    // let g = (event.target as SVGGElement).parentElement;

    // let consistres = svgHelper.WrapTxt(30,col.tooltip);
    // y -= consistres.length * this.linespacing;
    // let container = document.createElementNS(this.svgns, "g");

    // g.parentElement.appendChild(g);

    // if (g.getElementsByTagNameNS(this.svgns, 'g').length != 0) {
    //   g.getElementsByTagNameNS(this.svgns, 'g')[0].setAttributeNS(null, "visibility", "visible");
    //   return;
    // }
    // let text = document.createElementNS(this.svgns, "text");
    // text.setAttributeNS(null, "x", x);
    // text.setAttributeNS(null, "y", y);
    // text.setAttributeNS(null, "text-anchor", "start ");
    // let dy = 1;
    // let dx = 1;
    // // consist
    // text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "Состав:", true, 0));
    // dy++;
    // consistres.forEach(item => {
    //   text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, item, false, padding));
    //   dy++;
    // })
    // // countArea
    // text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "Общ. кол-во:", true, 0));
    // dy++;
    // let count = 0; col.cubes.forEach(item => { count += item.countArea });
    // text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, count, false, padding));
    // dy++;
    // // sumArea
    // text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "Общ. площ.:", true, 0));
    // dy++;
    // let sum = 0; col.cubes.forEach(item => { sum += item.sumArea });
    // text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, sum + "кв.км", false, padding));
    // dy++;
    // // subUnit
    // // text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "Комплексы:", true, 0));
    // // dy++;
    // // col.cubes.forEach(item => {
    // //   text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, item.subUnit, false, padding));
    // //   dy++;

    // // });

    // container.appendChild(text);

    // for (let i = 0; i < text.childNodes.length; i++)
    //   if (text.childNodes[i].textContent != undefined) {
    //     let tempdx = text.children[i].textContent.length;
    //     dx = tempdx < dx ? dx : tempdx;
    //   }
    // // rect
    // let rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
    // rect.setAttributeNS(null, "x", x);
    // rect.setAttributeNS(null, "y", y);
    // rect.setAttributeNS(null, "height", String(dy * this.linespacing));
    // rect.setAttributeNS(null, "width", String(Math.floor(7.3 * dx) + 3 * padding));
    // rect.setAttributeNS(null, "fill", 'rgb(255,255,255)');
    // rect.setAttributeNS(null, "rx", "2");
    // rect.setAttributeNS(null, "stroke", "lightgrey");
    // rect.setAttributeNS(null, "class", "cubepopup");

    // container.insertBefore(rect, container.firstChild);
    // g.parentElement.parentElement.appendChild(container);
    // g.addEventListener('mouseout', () => { container.remove(); });


  }
  tds2svg() {
    let w0 = 50;
    let w1 = 50;
    let w2 = 500;
    for (let ch of this.rayonCharts.charts)
      for (let col of ch.columns) {
        if (col.tooltip.length * 8 > w2) w2 = col.tooltip.length * 8;
      }
    let w3 = 100;
    let W = w0 + w1 + w2 + w3; this.tW = W;
    let h = 22;
    let res: string = "";
    res += svgHelper.rect(0, 0, w0, h, 'white', 'grey');
    res += svgHelper.rect(w0, 0, w1, h, 'white', 'grey');
    res += svgHelper.rect(w0 + w1, 0, w2, h, 'white', 'grey');
    res += svgHelper.rect(w0 + w1 + w2, 0, w3, h, 'white', 'grey');
    res += svgHelper.text(w0 / 2, h / 2, 16, 'black', '№');
    res += svgHelper.text(w0 + w1 + w2 / 2, h / 2, 16, 'black', 'Состав');
    res += svgHelper.text(w0 + w1 + w2 + w3 / 2, h / 2, 16, 'black', 'Площадь');
    let rome = 0;
    let arab = 1;
    let line = 1;
    for (let ch of this.rayonCharts.charts)
      if (ch.columns.length > 0) {
        res += svgHelper.rect(0, h * line, W, h, 'white', 'grey');
        res += svgHelper.text(W / 2, h * line + h / 2, 16, ch.epoch.color, ch.epoch.title + ' (' + ch.epoch.minage + ' - ' + ch.epoch.maxage + ' млн.лет)');
        line++;
        arab = 1;
        for (let col of ch.columns) {
          res += svgHelper.rect(0, h * line, w0, h, 'white', 'grey');
          res += svgHelper.rect(w0, h * line, w1, h, 'white', 'grey');
          res += svgHelper.rect(w0 + w1, h * line, w2, h, 'white', 'grey');
          res += svgHelper.rect(w0 + w1 + w2, h * line, w3, h, 'white', 'grey');
          res += svgHelper.text(w0 / 2 - 5, h * line + h / 2, 12, ch.epoch.color, this.rome[rome]);
          res += svgHelper.text(w0 / 2 + 10, h * line + h / 2 + 5, 10, ch.epoch.color, String(arab));
          res += svgHelper.rect(w0 + 5, line * h + 5, w1 - 10, h - 10, col.cubes[0].color, 'grey');
          res += svgHelper.text(w0 + w1 + w2 / 2, h * line + h / 2, 14, 'black', col.tooltip);
          res += svgHelper.text(w0 + w1 + w2 + w3 / 2, h * line + h / 2, 12, 'black', col.value + ' км2');
          arab++;
          line++;
        }
        rome++;
      }
    this.tH = line * h;
    return res;
  }
}
