import { Component, OnInit, Input } from '@angular/core';
import { Cube, Hatching } from '../_models/cube';

@Component({
  selector: 'app-formation-style',
  templateUrl: './formation-style.component.html'
})
export class FormationStyleComponent implements OnInit {
  
  @Input() cube:Cube;
  
  x = 50;
  message = "";

  Hatching=Hatching;

  constructor() { }

  ngOnInit() {
    this.message = `
Style:   ${this.cube.hatching}
Color:   ${this.cube.color}
Color1:  ${this.cube.color1}
Color2:  ${this.cube.color2}
Color3:  ${this.cube.color3}
    `;
  }

}
