
export class Cube {
    ageMinTxt: string;
    ageMaxTxt: string;
    ageMinY: number;
    ageMaxY: number;
    height: number;
    line: number;

    // from json
    baseaIds: string[];
    objectIds: string[];
    complexesNames: string[];
    geodynPositions: string[];
    saMin: string;
    saMax: string;
    zoning: string;
    formationName: string;
    formationClass: string;
    formationType: string;
    formationCode: string;
    formationAge: string;
    facii: string;
    hatching: Hatching;
    color: string;
    color1: string;
    color2: string;
    color3: string;
    
    samples: Sample[];
    globSamples: GlobSample[];
}
export class Sample {
    // from json
    baseaId: number;
    sampleID: number;
    subUnit: string;
    saMin: string;
    saMax: string;
    mapUnit: string;
    datingObject: string;
}
export class GlobSample
{
    baseaId: number;
    sampleID: string;
    subUnit: string;
    age_235U: string;
    age_238U: string;
    age_206PB: string
    journalName: string;
    pubYear: string; 
}
export enum Hatching{
    NoHatching = 0,     // 1	0	Без штриховки	
    OneColorAngle45,    // 2	1	Одноцветная штриховка под углом 45°
    OneColorAngle315,   // 3	2	Одноцветная штриховка под углом -45°
    BiColorAngle45,     // 4	3	Двуцветная штриховка под углом 45°
    TriColorAngle45,    // 5	4	Трехцветная штриховка под углом 45°		
    OneColorAngle90     // 6	5	Одноцветная вертикальная жирная штриховка		
}